import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as MUITextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { Field, FieldArray, Formik } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "../context/SnackbarContext";

const TOTAL_STEPS = 4;

const initialValues = {
  hostName: "",
  hostEmail: "",
  totalParticipants: 2,
  participants: [{ name: "", email: "" }],
  timeForEachParticipantInMinutes: 10,
  firstTurn: "you",
};

const validationSchema = [
  Yup.object().shape({
    hostName: Yup.string()
      .min(3, "Too short!")
      .max(15, "Too Long!")
      .required("Required"),
    hostEmail: Yup.string().email("Invalid email").required("Required"),
  }),
  Yup.object().shape({
    totalParticipants: Yup.number()
      .min(2, "Minimum participant should be 2")
      .required("Required"),
    participants: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .min(3, "Too short!")
          .max(15, "Too Long!")
          .required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
      })
    ),
  }),
  Yup.object().shape({
    timeForEachParticipantInMinutes: Yup.number()
      .min(1, "Minimum time should be 2")
      .required("Required"),
  }),
  Yup.object().shape({
    firstTurn: Yup.string().required("Required"),
  }),
];

export default function NewMeetingForm() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const [meeting, setMeeting] = useState();

  const handleSubmit = (values, { setSubmitting }) => {
    if (activeStep < TOTAL_STEPS - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSubmitting(false);
    } else {
      axios
        .post(process.env.REACT_APP_API_ENPOINT + "meetings", values)
        .then((res) => {
          if (res.data) {
            const { success, message, data } = res.data;

            if (success) {
              setMeeting(data);
              setActiveStep(4);
            } else {
              showSnackbar(message);
            }
          }
        })
        .catch(() => {
          showSnackbar("Unable to contact with server");
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handleJoin = () => {
    navigate("/meeting", {
      state: {
        ...meeting,
        name: meeting.hostName,
        email: meeting.hostEmail,
        host: true,
      },
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      window.location.origin + "/join/" + meeting.meetingId
    );
  };

  const handleCopyMeetingId = () => {
    navigator.clipboard.writeText(meeting.meetingId);
  };

  const StepFooter = ({ submitForm }) => (
    <div>
      <Button variant="contained" onClick={submitForm} sx={{ mt: 1, mr: 1 }}>
        {activeStep === TOTAL_STEPS - 1 ? "Finish" : "Next"}
      </Button>
      <Button
        disabled={activeStep === 0}
        sx={{ mt: 1, mr: 1 }}
        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
      >
        Back
      </Button>
    </div>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema[activeStep]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, values, touched, errors, setValues }) => (
        <Form>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>What is your name and email?</StepLabel>
              <StepContent>
                <div className="flex flex-col gap-3">
                  <Field
                    component={TextField}
                    label="Host name"
                    name="hostName"
                    size="small"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    label="Host email"
                    name="hostEmail"
                    size="small"
                    fullWidth
                  />
                  <StepFooter submitForm={submitForm} />
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Who would you like to invite?</StepLabel>
              <StepContent>
                <div className="flex flex-col gap-3">
                  <MUITextField
                    fullWidth
                    label="Total Participants (including host)"
                    name="totalParticipants"
                    size="small"
                    type="number"
                    value={values.totalParticipants}
                    disabled={isSubmitting}
                    onChange={(e) => {
                      const participants = [...values.participants];
                      const numberOfParticipants =
                        (e.target.value || e.target.value > 2
                          ? e.target.value
                          : 2) - 1;

                      if (participants.length > numberOfParticipants) {
                        participants.splice(numberOfParticipants);
                      } else {
                        for (
                          var i = 0;
                          i < numberOfParticipants - participants.length;
                          i++
                        ) {
                          participants.push({ name: "", email: "" });
                        }
                      }

                      setValues({
                        ...values,
                        totalParticipants: numberOfParticipants + 1,
                        participants,
                      });
                    }}
                    error={
                      touched.totalParticipants &&
                      Boolean(errors.totalParticipants)
                    }
                    helperText={
                      touched.totalParticipants && errors.totalParticipants
                    }
                  />

                  <h3 className="tet-gray text-sm">Participant 1 (Host):</h3>

                  <div className="pl-3 flex flex-col gap-3">
                    <MUITextField
                      label="Participant 1 name"
                      size="small"
                      value={values.hostName}
                      disabled={true}
                      fullWidth
                    />
                    <MUITextField
                      label="Participant 1 email"
                      size="small"
                      value={values.hostEmail}
                      disabled={true}
                      fullWidth
                    />
                  </div>

                  <FieldArray name="participants">
                    {() =>
                      Array.from(
                        Array(
                          values.totalParticipants > 2
                            ? values.totalParticipants - 1
                            : 1
                        ).keys()
                      ).map((_value, index) => (
                        <div key={index}>
                          <h3 className="tet-gray text-sm mb-3">
                            Participant {index + 2}:
                          </h3>

                          <div className="pl-3 flex flex-col gap-3">
                            <Field
                              component={TextField}
                              label={`Participant ${index + 2} name`}
                              name={`participants[${index}].name`}
                              size="small"
                              fullWidth
                            />
                            <Field
                              component={TextField}
                              label={`Participant ${index + 2} email`}
                              name={`participants[${index}].email`}
                              size="small"
                              fullWidth
                            />
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <StepFooter submitForm={submitForm} />
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>How long should each round last?</StepLabel>
              <StepContent>
                <div className="flex flex-col gap-3">
                  <Field
                    component={TextField}
                    label="Time for each participant in minutes"
                    name="timeForEachParticipantInMinutes"
                    size="small"
                    fullWidth
                  />
                  <StepFooter submitForm={submitForm} />
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Who gets to go first?</StepLabel>
              <StepContent>
                <div className="flex flex-col gap-3">
                  <FormControl fullWidth size="small">
                    <InputLabel id="first-turn">
                      Who gets to go first?
                    </InputLabel>
                    <Field
                      component={Select}
                      formHelperText={{ children: "Who gets to go first?" }}
                      id="first-turn"
                      name="firstTurn"
                      labelId="first-turn"
                      label="Who gets to go first?"
                      size="small"
                      defaultValue="you"
                    >
                      <MenuItem value="you" selected>
                        You
                      </MenuItem>
                      <MenuItem value="them">Them</MenuItem>
                      <MenuItem value="random">Random</MenuItem>
                    </Field>
                  </FormControl>
                  <StepFooter submitForm={submitForm} />
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Share and Join the meeting.</StepLabel>
              <StepContent>
                <Button
                  variant="outlined"
                  sx={{ mr: 3 }}
                  color="error"
                  onClick={handleCopyMeetingId}
                >
                  Copy meeting id
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mr: 3 }}
                  color="error"
                  onClick={handleCopyLink}
                >
                  Copy invite link
                </Button>
                <Button variant="outlined" onClick={handleJoin}>
                  Join
                </Button>
              </StepContent>
            </Step>
          </Stepper>
        </Form>
      )}
    </Formik>
  );
}
