import { Button } from "@mui/material";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { Form, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "../context/SnackbarContext";
import axios from "axios";

import logo from "../assets/app_icon.jpeg";

export default function Home() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleNewMeeting = () => navigate("/new");

  const handleJoin = (values, { setSubmitting }) => {
    axios
      .get(process.env.REACT_APP_API_ENPOINT + "meetings/" + values.meetingId)
      .then((res) => {
        if (res.data) {
          const { success, message, data } = res.data;

          if (success) {
            navigate("/join", {
              state: data,
            });
          } else {
            showSnackbar(message);
          }
        }
      })
      .catch(() => {
        showSnackbar("Unable to contact with server");
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="backdrop-blur w-screen h-screen flex flex-col gap-6 pt-24">
      <img
        src={logo}
        className="w-64 rounded-lg shadow self-center"
        alt="logo"
      />

      <div className="bg-white shadow-xl ring-1 ring-gray-900/5 mx-auto min-w-80 lg:w-4/12 md:w-8/12 max-w-10/12 p-7 pt-10 rounded">
        {/* <h1 className="text-2xl mb-5">Welcome!</h1> */}

        <Formik
          initialValues={{ meetingId: "" }}
          validationSchema={Yup.object().shape({
            meetingId: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
          })}
          onSubmit={handleJoin}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <div className="flex items-start gap-1">
                <Field
                  component={TextField}
                  label="Meeting ID"
                  name="meetingId"
                  size="small"
                  disabled={isSubmitting}
                  fullWidth
                />
                <Button onClick={submitForm} disabled={isSubmitting}>
                  Join
                </Button>
              </div>
              <p className="text-center m-3">OR</p>
              <Button
                variant="contained"
                fullWidth
                onClick={handleNewMeeting}
                disabled={isSubmitting}
              >
                New Meeting
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
