// import {
//   MicNoneOutlined,
//   MicOffOutlined,
//   Videocam,
//   VideocamOff,
// } from "@mui/icons-material";
import { useEffect, useRef } from "react";

export default function Video({ peer, local, current }) {
  const videoRef = useRef();

  const { user, mediaStream } = peer;

  // const micOn = mediaStream
  //   .getTracks()
  //   ?.find((t) => t.kind === "audio")?.enabled;

  // const videoOn = mediaStream
  //   .getTracks()
  //   ?.find((t) => t.kind === "video")?.enabled;

  useEffect(() => {
    if (mediaStream && videoRef.current)
      videoRef.current.srcObject = mediaStream;
  }, [videoRef, mediaStream]);

  return (
    <div className="flex justify-center items-center">
      <div
        className={`relative bg-white rounded-lg overflow-hidden max-h-full w-full aspect-video max-w-xl ${
          current ? "ring" : ""
        }`}
      >
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="w-full h-full"
          muted={local}
        />
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-2 text-white">
          {local ? `${user.name}(You)` : user.name}
        </div>
        {/* <div className="absolute right-11 top-2 bg-gray-700 bg-opacity-50 rounded-full p-1 text-center text-white">
          {micOn ? <MicNoneOutlined /> : <MicOffOutlined />}
        </div>

        <div className="absolute right-2 top-2 bg-gray-700 bg-opacity-50 rounded-full p-1 text-center text-white">
          {videoOn ? <Videocam /> : <VideocamOff />}
        </div> */}
      </div>
    </div>
  );
}
