import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import NewMeeting from "./pages/NewMeeting";
import Join from "./pages/Join";
import Meeting from "./pages/Meeting";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "new",
    element: <NewMeeting />,
  },
  {
    path: "join/:id",
    element: <Join />,
  },
  {
    path: "join",
    element: <Join />,
  },
  {
    path: "meeting",
    element: <Meeting />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

export default router;
