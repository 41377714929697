import { useEffect, useState } from "react";
import logo from "../assets/app_icon.jpeg";

export default function MeetingHeader({ meetingId, meetingStarted, turn }) {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    let interval;
    if (meetingStarted) {
      interval = setInterval(() => {
        const remainingTime = (new Date(turn.endAt) - new Date()) / 1000;
        const hr = parseInt(remainingTime / 3600);
        const min = parseInt(remainingTime / 60) % 60;
        const sec = parseInt(remainingTime % 60, 10);

        setTimeLeft(`${hr} hours ${min} minutes ${sec} seconds`);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [turn, meetingStarted]);

  return (
    <div className="flex justify-between items-center gap-3 flex-col md:flex-row">
      <img src={logo} className="w-36 rounded-lg shadow" alt="logo" />
      <div className="text-gray-500 overflow-hidden">
        <table className="text-sm">
          {!meetingStarted && (
            <tr>
              <td colSpan={2}>Meeting not started yet</td>
            </tr>
          )}
          {meetingStarted && turn && (
            <>
              <tr>
                <th className="text-left pr-1">Time Left: </th>
                <td>{timeLeft}</td>
              </tr>
              <tr>
                <th className="text-left pr-1">Next Turn: </th>
                <td>{turn.next.email}</td>
              </tr>
            </>
          )}
          <tr>
            <th className="text-left pr-1">Meeting Id: </th>
            <td>{meetingId}</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
